<template>
  <article v-if="preguntas?.length">
    <section class="flex flex-col text-center gap-y-6 lg:gap-y-10">
      <h2
        v-if="!soloPreguntas"
        class="text-2xl md:text-3xl lg:text-4xl text-green-500 font-bold"
      >
        PREGUNTAS FRECUENTES
      </h2>
      <div class="grid grid-cols-1" :class="{ 'lg:grid-cols-2': !unaColumna }">
        <div
          class="text-left col-span-1 p-2"
          v-for="pregunta in preguntas"
          :key="pregunta.id"
        >
          <Disclosure v-slot="{ open }">
            <DisclosureButton
              class="p-6 border-b border-gray-100 flex items-center w-full justify-between text-lg text-left"
              :class="open && ' text-green-500'"
            >
              <span class="font-bold w-11/12 text-base lg:text-lg">{{
                pregunta.pregunta
              }}</span>
              <PlusIcon class="w-5" v-if="!open" />
              <MinusIcon class="w-5" v-else />
            </DisclosureButton>
            <DisclosurePanel>
              <div class="px-8 py-4 text-sm lg:text-lg font-normal">
                <div v-html="pregunta.respuesta"></div>
              </div>
            </DisclosurePanel>
          </Disclosure>
        </div>
      </div>
      <a
        v-if="!soloPreguntas"
        class="text-sm lg:text-base text-green-500 font-bold underline cursor-pointer py-3"
        href="/preguntas-frecuentes"
      >
        VER MÁS PREGUNTAS FRECUENTES
      </a>
    </section>
  </article>
</template>
<script setup lang="ts">
  import {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
  } from "@headlessui/vue";
  import type { PreguntaFrecuente } from "@lib/interfaces";
  import { MinusIcon, PlusIcon } from "@heroicons/vue/24/outline";
  interface Props {
    preguntas: PreguntaFrecuente[] | null;
    soloPreguntas?: boolean;
    unaColumna?: boolean;
  }

  withDefaults(defineProps<Props>(), {
    soloPreguntas: false,
    unaColumna: false,
  });
</script>
